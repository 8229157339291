.mitsio-motu-container {
  width: 100%;
  max-width: 2000px;
  margin: 0 auto;
  padding: 50px 0;
  display: flex;
  min-height: calc(100vh - 70px);
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 2040px) {
    width: calc(100% - 40px);
    padding: 40px 20px;
  }
  @media only screen and (max-width: 992px) {
    width: 100%;
    padding: 30px 0;
  }
  &-inner {
    width: calc(100% - 60px);
    padding: 30px;
    margin: 0 auto;
    display: flex;
    max-width: 1200px;
    flex-direction: row;
    box-shadow: 0 2px 10px #ccc;
    border-radius: 10px;
    @media only screen and (max-width: 992px) {
      flex-direction: column;
      border-radius: 0;
    }
    &-left {
      width: 50%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      @media only screen and (max-width: 992px) {
        width: 100%;
        margin-bottom: 30px;
      }
    }
    &-right {
      width: 50%;
      @media only screen and (max-width: 992px) {
        width: 100%;
      }
      h1 {
        margin: 0 0 20px 0;
        font-family: 'Poppins', sans-serif;
        font-weight: normal;
        font-size: 32px;
        line-height: 42px;
        text-transform: uppercase;
        @media only screen and (max-width: 992px) {
          text-align: center;
        }
        @media only screen and (max-width: 768px) {
          font-size: 26px;
          line-height: 32px;
        }
      }
      p {
        font-size: 16px;
        line-height: 24px;
        font-family: 'Roboto', sans-serif;
        color: #333;
        margin: 0 0 15px 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
