@import "../../../assets/variables/variables.scss";

.accessibility-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  &-left {
    width: 50%;
    p {
      margin: 0;
      padding: 0;
      @media only screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
  &-right {
    width: 50%;
    &-status {
      width: 100%;
      border-radius: 4px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      //background-color: map-get($theme-colors, 'purple') !important;
      span {
        color: map-get($theme-colors, 'blue') !important;
        font-size: 12px;
      }
    }
  }
}

.green {
  .accessibility-content {
    &-right {
      &-status {
        //background-color: lighten(map-get($theme-colors, 'green'), 60%) !important;
        span {
          color: #000 !important;
        }
      }
    }
  }
}
