@import "../../../assets/variables/variables.scss";

.progress-bar {
  width: calc(100% - 20px);
  display: flex;
  flex-direction: row;
  height: 20px;
  margin: 0;
  border-top: solid 1px #ededed;
  padding: 20px 10px;
  &-inner {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0;
    align-items: center;
    &-title {
      h5 {

      }
    }
    &-block {
      width: calc(100% - 30px);
      height: 14px;
      display: flex;
      flex-direction: row;
      padding: 4px 0 4px 4px;
      border-radius: 4px;
      background-color: map-get($theme-colors, 'gray');
      &.start {
        background-color: map-get($theme-colors, 'purple');
      }
      span {
        display: block;
        padding: 0;
        margin: 0 4px 0 0;
        height: 100%;
        background-color: map-get($theme-colors, 'blue');
      }
    }
    &-text {
      width: 30px;
      display: flex;
      padding: 0 0 0 6px;
      p {
        display: flex;
        flex-direction: row;
        font-size: 13px;
        font-family: 'Roboto', sans-serif;
        color: #000000;
      }
    }
  }
  &.mobile {
    width: 100%;
    height: auto;
    padding: 7px 0;
    .progress-bar-inner {
      flex-direction: column;
    }
    .progress-bar-inner-title {
      margin-bottom: 5px;
    }
    .progress-bar-inner-title h5 {
      margin: 0;
      color: #000;
      font-size: 14px;
      font-family: 'Roboto', sans-serif;
      font-weight: normal;
    }
    .progress-bar-inner-block {
      margin-bottom: 5px;
    }
    .progress-bar-inner-text p {
      margin: 0;
    }
  }
}

.green {
  .progress-bar {
    &-inner {
      &-block {
        &.start {
          background-color: lighten(map-get($theme-colors, 'green'), 60%)
        }
        span {
          background-color: map-get($theme-colors, 'green');
        }
      }
    }
  }
}
