@import "../../assets/variables/variables.scss";

.login-container {
  width: 100%;
  height: 100vh;
  background-color: #f6f6ff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &-inner {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    @media only screen and (max-width: 460px) {
      width: 100%;
      max-width: 100%;
      padding: 0 20px;
    }
    &-logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: auto;
        @media only screen and (max-width: 768px) {
          width: 50px;
        }
      }
      h1 {
        margin: 20px 0 0 0;
        font-family: 'Poppins', sans-serif;
        font-size: 32px;
        line-height: 38px;
        @media only screen and (max-width: 768px) {
         font-size: 26px;
        }
      }
    }
    &-form {
      width: calc(100% - 80px);
      display: flex;
      flex-direction: column;
      margin: 40px 0 0 0;
      border: solid 4px #8b8ba7;
      padding: 40px;
      border-radius: 10px;
      @media only screen and (max-width: 768px) {
        width: calc(100% - 30px);
        margin: 20px 0 0 0;
        padding: 30px;
      }
      &-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        position: relative;
        @media only screen and (max-width: 768px) {
          margin-bottom: 25px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &.error {
          input {
            &:focus {
              border-color: map_get($theme-colors, 'red') !important;
            }
            &::placeholder {
              color: map_get($theme-colors, 'red');
            }
            border-color: map_get($theme-colors, 'red');
          }
        }
        .error {
          position: absolute;
          left: 0;
          bottom: -20px;
          p {
            font-size: 13px;
            color: map_get($theme-colors, 'red');
          }
        }
        p {
          font-family: 'Roboto', sans-serif;
          font-size: 20px;
          font-weight: 500;
          margin: 0;
          color: #8b8ba7;
          @media only screen and (max-width: 768px) {
            font-size: 16px;
          }
        }
        input {
          background-color: transparent;
          border: none;
          border-bottom: solid 4px #8587aa;
          padding: 10px 0;
          color: #3e3f53;
          outline: none;
          font-size: 22px;
          margin: 0;
          font-weight: 500;
          font-family: 'Roboto', sans-serif;
          @media only screen and (max-width: 768px) {
            padding: 10px 0;
            font-size: 18px;
          }
          &:focus {
            border-color: map-get($theme-colors, 'blue');
          }
          &::placeholder {
            color: #3e3f53;
          }
        }
        button {
          background-color: map-get($theme-colors, 'blue');
          color: #fff;
          border: none;
          padding: 15px 30px;
          border-radius: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          @media only screen and (max-width: 768px) {
            padding: 12px 20px;
          }
          &:disabled {
            cursor: not-allowed;
          }
          .text {
            font-size: 22px;
            line-height: 30px;
            @media only screen and (max-width: 768px) {
              font-size: 18px;
            }
          }
        }
        &.button {
          margin-top: 20px;
          @media only screen and (max-width: 768px) {
            margin-top: 15px;
          }
        }
        &.password {
          input {
            width: calc(100% - 40px);
            padding-right: 40px;
            position: relative;
            z-index: 1;
          }
          .hide-show {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            cursor: pointer;
            top: calc(50% + 10px);
            transform: translateY(-50%);
            right: 10px;
            z-index: 2;
          }
        }
      }
      .global-message {
        width: 100%;
        display: flex;
        justify-content: center;
        p {
          margin: 0;
          font-weight: bold;
          font-family: 'Roboto', sans-serif;
        }
        &.error {
          p {
            color: map-get($theme-colors, 'red');
          }
        }
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}

.green {
  .login-container {
    &-inner {
      &-form {
        &-item {
          input {
            &:focus {
              border-color: map-get($theme-colors, 'green');
            }
          }
          button {
            background-color: map-get($theme-colors, 'green');
          }
        }
      }
    }
  }
}
