.map-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  margin: 30px 0;
  padding: 0;
  border-radius: 10px;
  box-shadow: 0 2px 10px #ccc;
  position: relative;
  overflow: hidden;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    border-radius: 0;
  }
  /*&:before {
    content: "";
    display: block;
    width: 30%;
    height: 1px;
    background-color: #ddd;
    position: absolute;
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
  }*/
}
