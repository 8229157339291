.home-container {
  width: 100%;
  max-width: 2000px;
  margin: 0 auto;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 2040px) {
    width: calc(100% - 40px);
    padding: 40px 20px;
  }
  @media only screen and (max-width: 992px) {
    width: 100%;
    padding: 0;
    margin: 30px 0 0 0;
  }
}
